<template>
  <el-header style="position: sticky;top: 0;z-index: 99;background-color: #fff;padding: 0 200px;" height="60px">
    <router-link to="/" style="display: flex;align-items: center;">
      <img src="https://oss.culturalcloud.net/furong/202301/022037086xev.png" alt="">
      <div style="margin-left: 10px;">
        <h1 class="logo" style="color: #000000;font-size: 18px;">爱·悦读</h1>
        <span style="font-size: 12px;color: rgba(0, 0, 0, 0.8);">读书点亮未来</span>
      </div>
    </router-link>
    <el-menu style="margin-right: 10%;" router :default-active="$route.path" class="el-menu-demo" mode="horizontal">
      <el-menu-item index="/home">
        <i class="el-icon-s-home" style="color: black"></i>
        <span>首页</span>
      </el-menu-item>
      <el-menu-item index="/list">
        <i class="el-icon-receiving" style="color: black"></i>
        <span>图书列表</span>
      </el-menu-item>
      <el-menu-item index="/video">
        <i class="el-icon-video-camera-solid" style="color: black"></i>
        <span>党建视频</span>
      </el-menu-item>
      <el-menu-item index="/hall">
        <i class="el-icon-s-flag" style="color: black"></i>
        <span>亮旗大讲堂</span>
      </el-menu-item>
      <el-menu-item  index="" @click.native="$emit('show',true)">
        <!-- <a href="https://fjxsd.cctv.cn/#/pagoPage" target="_blank"> -->
          <i class="el-icon-connection" style="color: black"></i>
          <span>网上展馆</span>
        <!-- </a> -->
      </el-menu-item>
      <el-menu-item  index="">
        <a href="https://zgzd.wantuyun.cn/" target="_blank">
          <i class="el-icon-notebook-2" style="color: black"></i>
          <span>字根字典</span>
        </a>    
      </el-menu-item>
    </el-menu>
    <!-- <span style="font-size: 14px;">本平台资源数:74115本</span> -->
    <!-- <div style="margin-right: auto;">
      <el-input v-model="search" placeholder="请输入要搜索的电子书" size="normal" clearable @change="searchBook">
        <el-button slot="append" icon="el-icon-search"></el-button>
      </el-input>
    </div> -->
    <div class="bg-purple" v-if="islogin">
      <el-dropdown class="avatar-container ">
        <div class="avatar-wrapper">
          <el-avatar class="user-avatar" :src="userInfo.avatar">
            <i class="el-icon-s-custom" />
          </el-avatar>
          <p class="avatar-Name">{{userInfo.nickName}}</p>
        </div>
        <el-dropdown-menu slot="dropdown">
          <router-link to="/user">
            <el-dropdown-item>个人中心</el-dropdown-item>
          </router-link>
          <!-- <router-link target="_blank" to="/cms/main/cmsMine">
                        <el-dropdown-item>我的地盘</el-dropdown-item>
                    </router-link> -->
          <el-dropdown-item divided @click.native="logout">
            <span style="color:red">退出登录</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div v-else class="bg-purple">
      <div class="avatar-wrapper" @click="toLogin">
        <el-row :gutter="20" type="flex">
          <el-col :span="4"><i class="el-icon-user" /></el-col>
          <el-col :span="20">登录</el-col>
        </el-row>
      </div>
    </div>
  </el-header>
</template>

<script>
  export default {
    name: "Rheader",
    props: {
      show: {
        type: Boolean,
        default: false
      },
    },
    data() {
      return {
        userInfo: {},
        search: "",
        islogin: false
      };
    },
    created() {
      this.islogin = this.Storage.get('token')
      this.userInfo = this.Storage.get('userInfo')
    },
    updated() {
      this.islogin = this.Storage.get('token')
    },


    methods: {
      handleSelect() {},
      searchBook() {},
      toLogin() {
        // console.log(11);
        this.$router.push({
          path: '/login'
        })
      },
      logout() {
        this.$confirm('确定退出登录?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.Storage.clear();
          location.reload('/');
        })
      },
    },

  };
</script>

<style lang="scss" scoped>
  .el-header {
    // background-color: #ccc;
    border-bottom: 1px solid #ccc;
    display: flex;

    align-items: center;
    justify-content: space-between;

    .logo {
      font-size: 24px;
      font-weight: bold;
    }

    .bg-purple {
      //   float: right;
      width: 50px;
      display: flex;
      cursor: pointer;
    }

    .avatar-container {
      margin-right: 30px;
    }

    .avatar-wrapper {
      display: flex;
      //   justify-content: space-between;
      align-items: center;

      .user-avatar {
        background-color: #fff;
      }
    }

    .avatar-Name {
      margin-left: 10px;
      cursor: pointer;
      font-size: 16px;
      color: #000000;
    }
  }
</style>
