<template>
  <div>
    <r-header @show="showBox"></r-header>
    <transition name="fade-transform" mode="out-in">
        <router-view class="main"></router-view>
    </transition>
    <r-footer></r-footer>
    <el-dialog
      title="网上展馆"
      :visible.sync="show"
      width="50%"
      >
      <div style="display: flex;flex-wrap: wrap;justify-content: center;">
      <img v-for="item in exhibition"  width="" @click="openLink(item.url)" :src="item.img" alt="">

      </div>
     
       
     
      <!-- <span slot="footer">
        <el-button @click="show = false">Cancel</el-button>
        <el-button type="primary" @click="">OK</el-button>
      </span> -->
    </el-dialog>
  </div>
</template>

<script>
import RHeader from '@/common/header.vue'
import RFooter from '../common/footer.vue'
export default {
  components: {
    RHeader,
    RFooter,
  },
  data() {
    return {
      show: false,
      exhibition:[
        {url:'http://android.360elib.com:8781',img:'https://oss.culturalcloud.net/furong/202306/1215153302gs.png'},
        {url:'http://android.360elib.com:8791',img:'https://oss.culturalcloud.net/furong/202306/12152254lyx4.png'},
        {url:'http://android.360elib.com:8821',img:'https://oss.culturalcloud.net/furong/202306/12152352wxyw.png'},
        {url:'http://android.360elib.com:8831',img:'https://oss.culturalcloud.net/furong/202306/12152443tuce.png'},
        {url:'http://android.360elib.com:8741',img:'https://oss.culturalcloud.net/furong/202306/12152526ol7d.png'},
        {url:'http://android.360elib.com:8751',img:'https://oss.culturalcloud.net/furong/202306/121525589271.png'},
        {url:'https://fjxsd.cctv.cn/#/pagoPage',img:'https://oss.culturalcloud.net/furong/202306/121527013ig5.png'},
      ]
    }
  },
  methods: {
    showBox(value) {
      this.show=value
    },
    openLink(url){
      open(url)
    }
  },
};
</script>

<style lang="scss" scoped>
.fade-transform--move,
.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all .5s;
}

.fade-transform-enter {
  opacity: 0;
  transform: translateX(-30px);
}

.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
