<template>
    <div class="footer">
      <div class="container">
        <div class="siteinfo">
          <ul class="c0">
            <h3 class="c1">爱悦·读</h3>
            <ul>
              <li class="c2"><router-link to="/"><a class="c3">首页</a></router-link></li>
              <li class="c2"><router-link to="/list"> <a class="c3">图书列表</a></router-link></li>
              <!-- <li class="c2"><router-link to="/video"> <a class="c3">党建视频</a></router-link></li> -->
              <!-- <li class="c2"><router-link to="/hall"> <a class="c3">亮旗·大讲堂</a></router-link></li> -->
              <!-- <li class="c2"><a class="c3" @click="open5">五悦云大数据</a></li> -->
            </ul>
          </ul>
          <ul class="c0">
            <h3 class="c1">友情链接</h3>
            <ul>
              <li class="c2"><a class="c3" target="_blank" href="https://www.mct.gov.cn/">中华人民共和国文化和旅游部</a></li>
              <li class="c2"><a class="c3" target="_blank" href="https://www.culturedc.cn/">国家公共文化云</a></li>

            </ul>
          </ul>
          <ul class="c0">
            <h3 class="c1">友情链接</h3>
            <ul>
              <li class="c2"><a class="c3" target="_blank" href="http://whhlyt.hunan.gov.cn/">湖南文化和旅游厅</a></li>
              <li class="c2"><a class="c3" target="_blank" href="http://hnggwhy.wentiyun.cn/">湖南公共文旅云</a></li>
              <li class="c2"><a class="c3" target="_blank" href="http://www.hns-whg.cn/hunanszwhg/web/index.html">湖南数字文化馆</a></li>
              <li class="c2"><a class="c3" target="_blank" href="http://wlgd.changsha.gov.cn/">长沙市文化旅游广电局</a></li>
            </ul>
          </ul>
          <ul class="c0">
            <h3 class="c1">五悦云·文旅云平台</h3>
            <ul>
              <li class="c2"><a class="c3" target="_blank" href="https://furong.culturalcloud.net/">芙蓉文旅体云</a></li>
              <li class="c2"><a class="c3" target="_blank" href="https://kaifu.culturalcloud.net/">开福文旅体云</a></li>
              <li class="c2"><a class="c3" target="_blank" href="https://yhwlty.com/">雨花文旅体云</a></li>
              <!-- <li class="c2"><a class="c3" target="_blank" href="">湘乡文旅体云</a></li> -->
              <li class="c2"><a class="c3" target="_blank" href="https://liuyang.culturalcloud.net/">浏阳文旅体云</a></li>
            </ul>
          </ul>
          <ul class="c0">
            <h3 class="c1">五悦云·数字文化展示</h3>
            <ul>
              <!-- <li class="c2"><a class="c3" target="_blank" href=""></a></li> -->
              <li class="c2"><a class="c3" target="_blank" href="http://data.wantuyun.cn/#/index">五悦云·5G数据展示交互中心</a></li>
              <li class="c2"><a class="c3" target="_blank" href="https://zgzd.wantuyun.cn/">五悦云·字根书法字典</a></li>
              <!-- <li class="c2"><a class="c3" target="_blank" href="">五悦云大数据</a></li> -->
            </ul>
          </ul>
          <!-- <ul class="c0">
            <h3 class="c1">友情链接</h3>
            <ul>
              <li class="c2"><a class="c3" target="_blank" href="javascript:;">五悦云大数据</a></li>
              <li class="c2"><a class="c3" target="_blank" href="javascript:;">五悦云大数据</a></li>
              <li class="c2"><a class="c3" target="_blank" href="javascript:;">五悦云大数据</a></li>
            </ul>
          </ul> -->
  
          <ul class="c4">
            <li class="tel">
              <img width="100" src="https://oss.culturalcloud.net/furong/202212/08095342zx4o.png" alt="">
            </li>
            <li class="tel"><a class="c5" href="javascript:;" target="_blank">400-117-3773</a></li>
            <li class="time">周一至周五 10:00-18:00</li>
          </ul>
        </div>
  
        <div class="copyright">
          <h4 class="content-c2">Copyright ©2022, wantuyun.cn Co., Ltd. All Rights Reserved. Author:Renshuailin & Dengjianbo</h4>
          <ul class="privacy">
            <li class="content-c1"><a class="content-c0" @click="drawer1=true">版权声明</a></li>
            <li class="content-c1"><a class="content-c0" @click="drawer2=true">隐私条款</a></li>
            <!-- <li class="content-c1"><a class="content-c0" target="_blank" href="">开发者中心</a></li> -->
          </ul>
        </div>
        <div class="cop">
          <a class="content-c3" href="" target="_blank">
            <span class="content-c3">湘ICP备19006154号-1</span>
            <span class="content-c3">湘ICP证19006154号</span>
          </a>
        </div>
      </div>
      <el-drawer
        title="隐私条款"
        :visible.sync="drawer2"
        size="60%"
        >
        <div class="drawer">
          <p>1、用户同意，个人隐私信息是指那些能够对用户进行个人辨识或涉及个人通信的信息，包括下列信息：用户真实姓名，身份证号，手机号码，IP地址。而非个人隐私信息是指用户对本服务的操作状态以及使用习惯等一些明确且客观反映在本公司服务器端的基本记录信息和其他一切个人隐私信息范围外的普通信息，以及用户同意公开的上述隐私信息；</p>
          <p>2、保护用户(特别是未成年人)的隐私是本站的一项基本政策，本站将对用户所提供的资料进行严格的管理及保护，并使用相应的技术，防止用户的个人资料丢失、被盗用或遭篡改，保证不对外公开或向第三方提供单个用户的注册资料及用户在使用网络服务时存储在本站的非公开内容，但下列情况除外：</p>
          <p>2.1 事先获得用户的明确授权；</p>
          <p>2.2 根据有关的法律法规要求；</p>
          <p>2.3 按照相关政府主管部门的要求；</p>
          <p>2.4 为维护社会公众的利益；</p>
          <p>2.5 为维护本站的合法权益。</p>
          <p>3、任何时候如果您对我们的隐私策略有疑问，请拨打电话联系我们，我们会尽一切努力，请合理适当的范围内立即改善这个问题。</p>
          <p>知识产权</p>
          <p>1、本站的著作权人，未经本站许可，用户不得对该软件进行反向工程（reverse engineer）、反向编译（decompile）或反汇编（disassemble）。</p>
          <p>2、本站提供的网络服务中包含的任何文本、图片、图形、音频和/或视频资料均受版权、商标和/或其它财产所有权法律的保护，未经相关权利人同意，上述资料均不得在任何媒体直接或间接发布、播放、出于播放或发布目的而改写或再发行，或者被用于其他任何商业目的。所有这些资料或资料的任何部分仅可作为私人和非商业用途而保存在用户终端内。本站不就由上述资料产生或在传送或递交全部或部分上述资料过程中产生的延误、不准确、错误和遗漏或从中产生或由此产生的任何损害赔偿，以任何形式，向用户或任何第三方负责。</p>
          <p>3、本站所有作品内容仅代表作者自己的立场和观点，与本站无关，由作者本人承担一切法律责任。</p>
        </div>
      </el-drawer>
      <el-drawer
        title="版权声明"
        :visible.sync="drawer1"
        size="60%"
        >
        <div class="drawer">
          <p>本站部分作品来源用户上传，本站不曾对用户所传内容作任何形式的编辑与修改，如有发现用户上传作品有侵犯他人权利行为的，请及时与本站联系。</p>
          <p>本站坚决反对任何违反中华人民共和国有关著作权的法律法规的行为，秉承高度重视知识产权保护并遵守中华人民共和国各项知识产权法律、法规和具有约束力的规范性文件的态度,坚信著作权拥有者的合法权益应该得到尊重和依法保护。</p>
          <p>然而由于我们只作为转播平台为用户提供服务，无法对用户上传的所有作品内容进行充分调查监测，因此当著作权人或依法可以行使著作权的权利人（权利人）发现本站的附件内容侵犯其著作权时，权利人应事先向我公司发出“权利通知”,本站将根据中国法律法规和政府规范性文件采取措施移除相关内容或屏蔽相关链接。</p>
          <p>如果您认为本站上传的某一内容或资料被不恰当公布，您可以要求本站删除该内容或资料；但必须向本站提供以下材料，否则该要求将被视为未提出：</p>  
          <p>（1）著作权人身份证明文件；</p>
          <p>（2）著作权权属证明；</p>
          <p>（3）被侵权内容在网站中所处位置或链接；</p>
          <p>（4）您的联系方式，包括但不限于地址、电话和电子邮箱；</p>
          <p>（5）您表示该内容或资料的使用未经版权所有人或其代理人/许可人的合法授权的声明；</p>
          <p>（6）法律明文要求的其他资料</p>
          <p>一旦收到符合上述要求条款资料，本站将及时将您的作品下架或者删除。</p>
          <p>已上传作品的用户视为同意上述措施。本站不因此承担任何违约责任或其他任何法律责任，包括不承担因侵权指控不成立而给原上传用户带来损害的赔偿责任。</p>
          <p>本协议未涉及的问题参见国家有关法律法规，当本协议与国家法律法规冲突时，以国家法律法规为准。</p>
          <p>本站所收录作品及作品相关评论，与本站立场无关！</p>
        </div>
      </el-drawer>

    </div>
  </template>
  <script>
    export default {
      data () {
        return {
          drawer1:false,
          drawer2:false
        }
      },
     
      methods: {
        open3 () {
          this.$notify({
            title: '11',
            message: '22',
            type: 'warning'
          })
        },
        open4 () {
          this.$notify.info({
            title: '333',
            message: '444'
          })
        },
        open5 () {
          
          this.$notify({
            title: '444',
            message: '444',
            type: 'warning'
          })
        }
      },
      components: {
        // YButton
      }
    }
  </script>
  <style lang="scss" rel="stylesheet/scss" scoped>
  .drawer {
    padding:20px 50px;color: #999;font-size: 14px;
    p {
      text-indent: 2em;
      line-height: 32px;
    }
  }
    .footer {
      padding: 50px 0 20px;
      border-top: 1px solid #e6e6e6;
      background: #fafafa;
    //   margin-top: 60px;
      height: 350px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .container {
      width: 1220px;
    }
    
    .siteinfo {
      height: 100px;
      padding: 50px 0 130px;
      border-bottom: 1px solid #e6e6e6;
      position: relative;
    }
  
    .c0 {
      width: 170px;
      line-height: 1;
      float: left;
    }
  
    .c1 {
      color: #646464;
      font-size: 12px;
      padding: 0 0 14px;
    }
  
    .c2 {
      color: #c3c3c3;
      font-size: 12px;
      padding: 6px 0;
    }
  
    .c3 {
      color: #969696;
    }
  
    .c4 {
      position: absolute;
      right: 0;
      overflow: hidden;
      line-height: 34px;
    }
  
    .tel {
      text-align: right; 
      font-size: 30px;
      line-height: 1;
      color: #646464;
    //   top: -2px;
      position: relative;
    }
  
    .c5 {
      color: #646464;

    }
  
    .time {
      margin-top: 5px;

      clear: both;
      width: 241px;
      font-size: 12px;
      line-height: 18px;
      color: #c3c3c3;
      text-align: right;
    }
  
    .online {
      clear: both;
      width: 241px;
      font-size: 12px;
      line-height: 18px;
      color: #c3c3c3;
      text-align: right;
    }
  
    .button {
      width: 130px;
      height: 34px;
      font-size: 14px;
      color: #5079d9;
      border: 1px solid #dcdcdc;
      margin-top: 8px;
    }
  
    .copyright {
      color: #434d55;
      font-size: 12px;
      padding: 40px 0 0;
      display: flex;
      align-items: left;
    }
  
    .privacy {
      float: left;
      margin: 0 0 0 12px;
    }
  
    .content-c0 {
      color: #5079d9;
      cursor: pointer;
      text-decoration: none;
      &:hover {
        color: #3A5FCD;
      }
    }
  
    .content-c1 {
      float: left;
      line-height: 12px;
      padding: 1px 10px 0;
      border-left: 1px solid #ccc;
    }
    
    .content-c2 {
      float: left;
      height: 15px;
      line-height: 15px;
      color: #757575;
    }
  
    .cop {
      clear: both;
      padding: 10px 0 0;
      height: 15px;
    }
  
    .content-c3 {
      margin-right: 20px;
      color: #bdbdbd;
      font-size: 12px;
      height: 12px;
      line-height: 1;
    }
  </style>
  